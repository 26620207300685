import {useState, useEffect} from "react";
import MODELS from './models.json'
import FAMILIES from './families.json'
import SIZES from './sizes.json'
import MATERIALS from './materials.json'
import DIALS from './dials.json'
import STRAPS from './straps.json'

/**
 * @see https://precisionwatches.com/decoding-longines-reference-numbers/
 * @returns {JSX.Element}
 * @constructor
 */
export function LonginesRef() {
    const [explanation, setExplanation] = useState('');
    const [ref, setRef] = useState('3.780.4.96.6');

    useEffect(() => {
        parseRef(ref)
    }, [ref]);

    function parseRef(ref) {
        console.log("parse ref: " + ref);

        const MIN_LENGTH = 5;
        const MIN_PARTS = 2;
        const SEPARATOR = "."
        const MIN_MODEL_LENGTH = 3;
        const ERROR_BAD_REF_FORMAT = "References must be in L1.234 format.";

        let explanation = [];

        // remove front "L" if necessary
        if (Array.from(ref)[0] === "L") {
            const s = ref.substring(1, ref.length);
            setRef(s);
            ref = s;
        }

        // add dots if necessary
        // if (!ref.includes(".")) {
        //     console.log("need to add dots");
        //     let refParts = Array.from(ref);
        //     for (const length of [1, 3, 1, 2, 1]) {
        //         for (let j = 0; j < length; j++) {
        //
        //         }
        //     }
        // }

        if (ref.length < MIN_LENGTH) {
            setExplanation("References must be at least " + MIN_LENGTH + " characters long.");
            return;
        }
        const refParts = ref.split(SEPARATOR)
        if (refParts.length <= MIN_PARTS) {
            setExplanation(ERROR_BAD_REF_FORMAT)
        }

        setRef(ref);

        // =========== parse family
        const familyKey = refParts[0];
        const familyDescription = FAMILIES[familyKey];
        const family = 'Family: L' + familyKey + ': ' + familyDescription;
        explanation.push(family);

        // =========== parse model
        const modelKey = refParts[1];
        if (modelKey.length < MIN_MODEL_LENGTH) {
            setExplanation(ERROR_BAD_REF_FORMAT)
        }
        // get first character of modelKey
        const modelSizeKey = Array.from(modelKey)[0]
        const modelSizeDescription = SIZES[modelSizeKey];
        explanation.push('Model Size: ' + modelSizeKey + ': ' + modelSizeDescription);
        // parse remaining model
        const modelDescription = MODELS[modelKey];
        explanation.push('Model: ' + modelKey + ': ' + modelDescription);

        //  =========== parse material
        if (refParts.length >= 3) {
            const materialKey = refParts[2];
            const materialDescription = MATERIALS[materialKey];
            explanation.push('Material: ' + materialKey + ': ' + materialDescription);
        }

        // ============ parse dial
        if (refParts.length >= 4) {
            const dialKey = refParts[3];
            const dialDescription = DIALS[dialKey];
            explanation.push('Dial: ' + dialKey + ': ' + dialDescription);
        }

        // ============ parse strap
        if (refParts.length >= 5) {
            const strapKey = refParts[4];
            const strapDescription = STRAPS[strapKey];
            explanation.push('Strap: ' + strapKey + ': ' + strapDescription);
        }

        // set full explanation
        setExplanation(explanation.map(explanation =>
                <li>
                    {explanation}
                </li>
            )
        );
    }

    return (
        <div className="longines-ref">
            <div>
                Enter ref: <br/>
                L<input type="text" id="longines-ref" value={ref} onChange={e => parseRef(e.target.value)} />
            </div>
            <hr/>
            <div>
                Reference: <br/>
                <span id="leading-l">L</span>{ref}
            </div>
            <div>
                Explanation: <br/>
                <ul>{explanation}</ul>
            </div>
            <div>
                <img alt="watch" src={"model-photos/" + ref + ".png"} />
            </div>
        </div>
    )
}